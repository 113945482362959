import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import './index.scss'
import logo from '../../assets/images/logo-rfo.png';
import Instagram from '../../assets/images/instagram.svg'
import Facebook from '../../assets/images/facebook.svg'
import Linkedin from '../../assets/images/linkedin.svg'
import { Tooltip as ReactTooltip } from "react-tooltip";

const Footer = () => {

    const [t] = useTranslation("global");

    return(
        <>
        <div className="footer">
            <div className="container containerFooter">
                <div className="col logo">
                    <a href="/" className="logoFooter">
                        <img src={logo} alt="RFO Fibra Óptica" />
                    </a>
                    <span>{t("footer.company")}</span>
                    <span>{t("footer.phone")}</span>
                    <span>{t("footer.cnpj")}</span>
                </div>
                <div className="col menuFooter">
                    <Link to="/">{t("header.menu.item1")}</Link>
                    <Link to="/quem-somos">{t("header.menu.item2")}</Link>
                    <Link to="/servicos">{t("header.menu.item3")}</Link>
                    <Link to="/contato">{t("header.menu.item4")}</Link>
                </div>
                <div className="col socials">
                    <h3>{t("footer.titleSocial")}</h3>
                    <a href="https://www.linkedin.com/company/rfo-fibra-optica/" className="" data-tooltip-id="lk" target="_blank" rel="noreferrer">
                        <img src={Linkedin} alt="" />
                    </a>
                    <a href="https://www.facebook.com/rfofibraoptica" className="" data-tooltip-id="fb" target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="" />
                    </a>
                    <a href="https://www.instagram.com/rfo_fibra_optica/" className="" data-tooltip-id="in" target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="" />
                    </a>

                    

                    
                </div>
            </div>
            <ReactTooltip
                id="lk"
                place="bottom"
                variant="info"
                content={t("footer.titleSocial")}
            />
            <ReactTooltip
                id="fb"
                place="bottom"
                variant="info"
                content={t("footer.titleSocial")}
            />
            <ReactTooltip
                id="in"
                place="bottom"
                variant="info"
                content={t("footer.titleSocial")}
            />
        </div>
        <div className="footer-bottom">
            <div className="container">
                <span>{t("footer.copywrite")}</span>
            </div>
        </div>
        </>
    )
}

export default Footer