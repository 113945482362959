import { useTranslation } from 'react-i18next'
import './index.scss'
import { useState } from 'react'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormContact = () => {

    const [t] = useTranslation("global")

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [msg,setMsg] = useState("")

    const handleSendForm = (e: any) =>{
        e.preventDefault();

        const templateParams = { 
            from_name: nome,
            from_email: email,
            from_phone: phone,
            message: msg
        }
        emailjs.send("service_2tody3f","template_anziwbq", templateParams, "PzZ1gy2hd0bmEw8Uo")

        .then((res) => {
            console.log("email enviado", res.status, res.text)
            setNome("");
            setEmail("");
            setPhone("")
            setMsg("")

            toast.success("Contato enviado com sucesso!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });

        }, (err) => {
            console.log("erro", err)
            toast.error("Ops! Algo deu errado", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        })



    }

    const handleChangePhone = (e: any) => {
        // tratar para formataçao
        const number = e.target.value;
        setPhone(number)
    }


    return(
        <div className='formContact'>
            <form onSubmit={handleSendForm} data-aos="zoom-out" data-aos-delay="100">
                <div className="group">
                    <label htmlFor="nome">{t("contact.form.name")}</label>
                    <input
                        type="text"
                        name="nome"
                        id="nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        required
                        placeholder={t("contact.form.holders.1")}
                    />
                </div>
                
                <div className="group">
                    <label htmlFor="email">{t("contact.form.email")}</label>
                    <input 
                        type="email"    
                        name="email"
                        id="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t("contact.form.holders.2")}
                    />
                </div>
                <div className="group">
                    <label htmlFor="telefone">{t("contact.form.phone")}</label>
                    <input
                        type="number"
                        name="telefone"
                        id=""
                        required
                        value={phone}
                        onChange={handleChangePhone}
                        placeholder={t("contact.form.holders.3")}
                    />
                </div>
                <div className="group">
                    <label htmlFor="mensagem">{t("contact.form.message")}</label>
                    <textarea 
                        name="mensagem"
                        id=""
                        required
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        placeholder={t("contact.form.holders.4")}
                    ></textarea>
                </div>
                <button
                    className="buttonOrder buttonSubmit"
                    type="submit"
                >{t("contact.form.button")}
                </button>
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default FormContact