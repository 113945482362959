import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/global.scss'
import App from './App';
import global_pt from './translations/pt/global.json'
import global_en from './translations/en/global.json'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: {escapeValue: false},
  lng: "pt",
  resources: { 
    pt: { 
      global: global_pt,
    },
    en: { 
      global: global_en,
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

