import { useTranslation } from "react-i18next"
import Banner from "../../components/Banner"
import './index.scss'
import Timeline from "../../components/Timeline"
import Partner from "../../components/Partner"
import ScrollToTop from "../../components/ScrollToTop"


const QuemSomos = () => {

    const [t] = useTranslation("global")

    
    
    return(
        <>
            <ScrollToTop />
            <div className="quem-somos">
                <Banner 
                    title={t("header.menu.item2")} 
                    id="quem-somos"
                />
                <div className="container d-flex justify-content-between px-4 p-sm-2 flex-sm-column">
                    <div className="left" data-aos="fade-in" data-aos-delay="300">
                        <p className="text">
                            {t("whoweare.description.p1")}
                        </p>
                        <p className="text">
                            {t("whoweare.description.p2")}
                        </p>
                        <p className="text">
                            {t("whoweare.description.p3")}
                        </p>
                        <p className="text">
                            {t("whoweare.description.p4")}
                        </p>
                    
                    </div>
                    <div className="right" data-aos="fade-in" data-aos-delay="400">
                        <p className="text">
                            {t("whoweare.description.p5")}
                        </p>
                        <p className="text">
                            {t("whoweare.description.p6")}
                        </p>
                        <p className="text">
                            {t("whoweare.description.p7")}
                        </p>
                        <p className="text">
                            {t("whoweare.description.p8")}
                        </p>
                    </div>
                </div>
                <Timeline />
                <Partner />
            </div>
        </>
    )
}

export default QuemSomos