import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Header from './components/Header';
import QuemSomos from './pages/QuemSomos';
import Footer from './components/Footer';
import Servicos from './pages/Servicos';
import Contato from './pages/Contato';
import Error from './pages/Error';
import LandingPage from './pages/LandingPage';

function RoutesApp(){
    return(
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={ <Home /> } />
                <Route path="/quem-somos" element={ <QuemSomos /> } />
                <Route path="/servicos" element={ <Servicos /> } />
                <Route path="/contato" element={ <Contato /> } />
                <Route path="/sejaumcliente" element={ <LandingPage /> } />
                <Route path="*" element={ <Error /> } />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default RoutesApp;