import '../MainBanner/index.scss'
import ArrowDown from '../ArrowDown';


const Banner = (props: any) => {
    return(
        <div className="mainBanner innerBanner" id={props.id} data-aos="fade-in">
            <div className="container">
                <div className="caption" data-aos="fade-down" data-aos-delay="150">
                    <h1>{props.title}</h1>
                </div>
            </div>
            <ArrowDown hash="#footer" />
        </div>
    )
}

export default Banner;