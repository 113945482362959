import MainBanner from "../../components/MainBanner"
import CompServices from "../../components/CompServices";
import BannerWhatsapp from "../../components/BannerWhatsapp";
import ScrollToTop from "../../components/ScrollToTop";

const Home = () => {

    

    return(
        <>
            <ScrollToTop />
            <MainBanner />
            <CompServices />
            <BannerWhatsapp />
        </>
    )
}

export default Home;