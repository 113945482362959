import './index.scss';

const ArrowDown = (props: { hash: string; }) => {
    return(
        <div className="arrowDown">
            <a href={props.hash}>
                <button className="icon-arrow">
                    
                </button>
            </a>
        </div>
        
    )
}

export default ArrowDown;