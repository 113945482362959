import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner";
import Partner from "../../components/Partner";
import './index.scss'
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";

const Servicos = () => {

    const [t] = useTranslation("global")

    return (
        <>
            <ScrollToTop />
            <div>
                <Banner 
                    title={t("header.menu.item3")} 
                    id="quem-somos"
                />
                <div className="container">
                    <div className="wrapService">
                        <div className="service" id="one" data-aos="fade-up">
                            <div className="image one">

                            </div>
                            <div className="text" data-aos="fade-in" data-aos-delay="200">
                                <h2>{t("services.first.title")}</h2>
                                <span>{t("services.first.description")}</span>
                                <Link to="/contato">
                                    <button className="buttonOrder">
                                        {t("services.cta")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="service" id="two" data-aos="fade-up">
                            <div className="image two order-2">

                            </div>
                            <div className="text" data-aos="fade-in" data-aos-delay="200">
                                <h2>{t("services.second.title")}</h2>
                                <span>{t("services.second.description")}</span>
                                <span>{t("services.second.descExtra")}</span>
                                <Link to="/contato">
                                    <button className="buttonOrder">
                                        {t("services.cta")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="service" id="three" data-aos="fade-up">
                            <div className="image three">
                                
                            </div>
                            <div className="text" data-aos="fade-in" data-aos-delay="200">
                                <h2>{t("services.third.title")}</h2>
                                <span>{t("services.third.description")}</span>
                                <Link to="/contato">
                                    <button className="buttonOrder">
                                        {t("services.cta")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="service" id="four" data-aos="fade-up">
                            <div className="image four order-2">

                            </div>
                            <div className="text" data-aos="fade-in" data-aos-delay="200">
                                <h2>{t("services.four.title")}</h2>
                                <span>{t("services.four.description")}</span>
                                <Link to="/contato">
                                    <button className="buttonOrder">
                                        {t("services.cta")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="service" id="five" data-aos="fade-up">
                            <div className="image five"></div>
                            <div className="text" data-aos="fade-in" data-aos-delay="200">
                                <h2>{t("services.five.title")}</h2>
                                <span>{t("services.five.description")}</span>
                                <Link to="/contato">
                                    <button className="buttonOrder">
                                        {t("services.cta")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Partner />
            </div>
        </>
    )
}

export default Servicos;