
import { useState, useEffect } from 'react';
import logo from '../../assets/images/logo-rfo.png'
import iconOk from '../../assets/images/icon-ok.svg'
import './index.scss';
import { useLocation } from 'react-router-dom';
import { db } from '../../firebaseConnection';
import { collection, addDoc} from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LandingPage = () => {

    const [name, setName] = useState("")
    const [business, setBusiness] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [showform, setShowform] = useState(true)
    const [txtButton, setTxtbutton] = useState('quero ser atendido por especialistas')



    const location = useLocation();

    useEffect(() => {
        
        if (location.pathname === '/sejaumcliente') {
            document.body.classList.add('landingpage');
        } else {
            document.body.classList.remove('landingpage');
        }

        return () => {
            document.body.classList.remove('landingpage');
        };

        

    }, [location.pathname]);

    

    const handleSubmit = async function (e: any){
        e.preventDefault();
        setTxtbutton('Enviando contato..')
        await addDoc(collection(db, "leads"), {
            nome: name,
            empresa: business,
            email: email,
            telefone: phone
        })
        .then(() => {
            setBusiness("")
            setEmail("")
            setPhone("")
            setShowform(false)
        })
        .catch((e) => {
            console.log("erro" + e)
            setShowform(true)
            toast.error("Ops, deu algo de errado", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        })
        
    }
    return(
        
        <div className="landingpage">
            {/* <HeaderLp /> */}
            <div className="carousel-bg">
                <div className="container">
                    <img src={logo} alt="RFO Fibra Óptica" />
                    <h1 className="titlelp">
                    <strong>Conecte sua Indústria ao Futuro:</strong> Soluções Ópticas de Alta Performance para Usinas e Fábricas!
                    </h1>
                    <div className="serviceslp desktop">
                        <div className="item item-one" data-aos="fade-up" data-aos-delay="300"><h2>Cabeamento Estruturado</h2></div>
                        <div className="item item-two" data-aos="fade-up" data-aos-delay="400"><h2>Automação Óptica Industrial</h2></div>
                        <div className="item item-three" data-aos="fade-up" data-aos-delay="500"><h2>Certificação Óptica</h2></div>
                        <div className="item item-four" data-aos="fade-up" data-aos-delay="600"><h2>Manuntenção Corretiva e Preventiva</h2></div>
                    </div>
                    
                </div>
            </div>
            <form action="" className="formLead">
                    { showform ? 
                    (<div>
                        <h2>Não perca tempo, conheça nossos serviços e se surpreenda com a qualidade.</h2>
                        <div className="form-group">
                            <label htmlFor="nome">Nome:</label>
                            <input 
                                type="text"
                                name="name"
                                value={name}
                                id=""
                                onChange={(e) => {setName(e.target.value)}}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="empresa">Empresa:</label>
                            <input 
                                type="text"
                                name="business"
                                id="" 
                                required
                                value={business}
                                onChange={(e) => {setBusiness(e.target.value)}}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">E-mail:</label>
                            <input 
                                type="email"
                                name="email"
                                id="" 
                                required
                                value={email}
                                onChange={(e) => {setEmail(e.target.value)}}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="telefone">Telefone:</label>
                            <input 
                                type="text"
                                name="phone"
                                id=""
                                required
                                value={phone}
                                onChange={(e) => {setPhone(e.target.value)}}
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" onClick={handleSubmit}>
                                {txtButton}
                            </button>
                        </div>
                    </div>)
                    : 
                    (<div className="leadsuccess">
                        <img src={iconOk} alt="Agradecemos o contato" data-aos="fade-in" data-aos-delay="100" />
                        <p data-aos="fade-in" data-aos-delay="200">
                        Obrigado <strong>{name}</strong>,<br />
                        Seu contato foi enviado com sucesso.<br /><br />
                        Retornamos em breve.
                        </p>
                    </div>) }
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default LandingPage;