import { useTranslation } from 'react-i18next';
import './index.scss'
import ArrowDown from '../ArrowDown';
import { Link } from 'react-router-dom';

const MainBanner = () => {

    const [t] = useTranslation("global");

    return(
        <div className="mainBanner">
            <div className="container">
                <div
                    className="caption"
                    data-aos="fade-up"
                >
                    <h1>{t("mainBanner.title")}</h1>
                    <span>{t("mainBanner.description")}</span>
                    <Link to="/contato">
                        <button className="buttonOrder">
                            {t("mainBanner.button")}
                        </button>
                    </Link>
                    
                </div>
            </div>
            <ArrowDown hash="#footer" />
        </div>
    )
}

export default MainBanner;