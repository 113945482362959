import { useTranslation } from "react-i18next";
import ScrollToTop from "../../components/ScrollToTop";
import './index.scss';

const Error = () => {

    const [t] = useTranslation("global")
    return(
        <>
            <ScrollToTop />
            <div className="pageError">
                <span>Oops,</span>{t("404.title")}
            </div>
        </>
    )
}

export default Error;