import { useTranslation } from "react-i18next";
import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import './index.scss'
import svg from '../../assets/images/2.svg'
import { Link, useLocation } from "react-router-dom";

const CompServices = () => {

    const [t] = useTranslation("global");

    
    return(
        <div className="services">
            <img src={svg} alt="teste" className="spacer-fiber"/>
            <h1 data-aos="fade-up" data-aos-delay="100">{t("services.title")}</h1>
            <div className="container compServicesContainer">
            <Glider
                
                slidesToShow={1}
                slidesToScroll={1}
                scrollLock
                responsive={[
                    {
                        breakpoint: 775,
                        settings: {
                            slidesToShow: "auto",
                            slidesToScroll: "auto",
                            duration: 0.25,
                        },
                    },
                ]}
            >
                
                <div className="slide box-service" data-aos="fade-up" data-aos-delay="300">
                    <div className="infos">
                        <h3>{t("services.first.title")}</h3>
                        <div className="image first">
                            
                        </div>
                        <span>{t("services.first.shortDescription")}</span>
                        <button className="textLink">
                            <Link to="/servicos#one">{t("services.first.button")}</Link>
                        </button>
                    </div> 
                </div>
                <div className="slide box-service" data-aos="fade-up" data-aos-delay="400">
                    <div className="infos">
                        <h3>{t("services.second.title")}</h3>
                        <div className="image second">
                        </div>
                        <span>{t("services.second.shortDescription")}</span>
                        <button className="textLink">
                            <Link to="/servicos#two">{t("services.second.button")}</Link>
                        </button>
                    </div> 
                </div>
                <div className="slide box-service" data-aos="fade-up" data-aos-delay="500">
                    <div className="infos">
                        <h3>{t("services.third.title")}</h3>
                        <div className="image third">
                        </div>
                        <span>{t("services.third.shortDescription")}</span>
                        <button className="textLink">
                            <Link to="/servicos#three">{t("services.third.button")}</Link>
                        </button>
                    </div> 
                </div>
            </Glider>
            </div>
            <Link to="/servicos">
                <button className="buttonOrder seeAll"  data-aos="zoom-in-up">
                    {t("services.seeAll")}
                </button>
            </Link>
            
        </div>
    )
}

export default CompServices;