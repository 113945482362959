import React, { useEffect } from 'react';
import RoutesApp from "./routes";
import AOS from "aos";
import 'aos/dist/aos.css'



function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  },[])


  return (
    <div className="App">
      <RoutesApp />
    </div>
  );
}

export default App;
