// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCPOe5-ExETKm130LNtCHoC45d9-1-PI58",
    authDomain: "lprfo-2a381.firebaseapp.com",
    projectId: "lprfo-2a381",
    storageBucket: "lprfo-2a381.appspot.com",
    messagingSenderId: "883831398330",
    appId: "1:883831398330:web:c6c3450aa34e66e8c14783",
    measurementId: "G-FRKTTKGB46"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const db = getFirestore(firebaseApp)

export { db };