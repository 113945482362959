import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner";
import FormContact from "../../components/FormContact";
import ScrollToTop from "../../components/ScrollToTop";

const Contato = () => {

    const [t] = useTranslation("global")


    return(
        <>
            <ScrollToTop />
            <Banner
                title={t("header.menu.item4")}
                id="contato"
            />
            <FormContact />
        </>
    )
}

export default Contato;