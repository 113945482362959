import { useTranslation } from "react-i18next";
import FlagBr from '../../assets/images/flag-br.svg'
import FlagEn from '../../assets/images/flag-en.svg'
import './index.scss';
import { Tooltip as ReactTooltip } from "react-tooltip";


const SwitchLanguage = () => {

    const [t, i18n] = useTranslation("global")

    const handleChangeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    return(
        <div>
            <button onClick={() => handleChangeLanguage("pt")} data-tooltip-id="my-tooltip-1">
                <img src={FlagBr} alt="Português" />
            </button>
            <button onClick={() => handleChangeLanguage("en")} data-tooltip-id="my-tooltip-2">
                <img src={FlagEn} alt="Inglês" />
            </button>
            <ReactTooltip
                id="my-tooltip-1"
                place="left"
                variant="info"
                content={t("header.tooltip.pt")}
            />
            <ReactTooltip
                id="my-tooltip-2"
                place="right"
                variant="info"
                content={t("header.tooltip.en")}
            />
        </div>
    )
}

export default SwitchLanguage;