import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SwitchLanguage from "../SwitchLanguage";
import logo from '../../assets/images/logo-rfo.png'
import './index.scss'
import { useState } from "react";

const Header = () => {

    const [t] = useTranslation("global");
    const [openMenu, setOpenMenu] = useState(false)
    

    const OpenMenuMobile = () => {

        document.body.classList.add('scroll-block')

        
        setOpenMenu(true)
        
    }

    const CloseMenu = () => {
        
        document.body.classList.remove('scroll-block')
        setOpenMenu(false)
    }
    return(
        <>
            
            <div className="header">
                <div className="container">
                    <div className="logo">
                        <a href="/">
                            <img src={logo} alt="RFO Fibra Óptica" />
                        </a>
                    </div>
                    <div className="menu">
                        <Link to="/">{t("header.menu.item1")}</Link>
                        <Link to="/quem-somos">{t("header.menu.item2")}</Link>
                        <Link to="/servicos">{t("header.menu.item3")}</Link>
                        <Link to="/contato">{t("header.menu.item4")}</Link>
                    </div>
                    <SwitchLanguage />
                    <div className="bars" onClick={OpenMenuMobile}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                { openMenu ? (
                    <div className="menu-mobile">
                        <div className="close" onClick={CloseMenu}>
                            X
                        </div>
                        <Link to="/" onClick={CloseMenu}>{t("header.menu.item1")}</Link>
                        <Link to="/quem-somos" onClick={CloseMenu}>{t("header.menu.item2")}</Link>
                        <Link to="/servicos" onClick={CloseMenu}>{t("header.menu.item3")}</Link>
                        <Link to="/contato" onClick={CloseMenu}>{t("header.menu.item4")}</Link>
                    </div>
                ) : null
                }
            </div>
        </>
    )
}

export default Header;