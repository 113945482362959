import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import './index.scss'
import { useTranslation } from 'react-i18next';

const Timeline = () => {

    const [t] = useTranslation("global")
    
    
    return(
        <div className="container">
            <h1 className="title-section" data-aos="fade-up">{t("whoweare.timeline.title")}</h1>
            <div className="timeline" data-aos="fade-up" data-aos-delay="200">
                <Glider
                    hasArrows
                    slidesToShow={1}
                    slidesToScroll={1}
                    scrollLock
                    responsive={[
                        {
                            breakpoint: 775,
                            settings: {
                                slidesToShow: 1.3,
                                slidesToScroll: 1,
                                duration: 0.25,
                            },
                        },
                    ]}
                    >
                    <div className="slide">
                        <div className="timeline-item">
                            <div className="timeline-year">2007</div>
                            <h2 className="timeline-title">{t("whoweare.timeline.2007.title")}</h2>
                            <span>{t("whoweare.timeline.2007.description")}</span>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="timeline-item">
                            <div className="timeline-year">2010</div>
                            <h2 className="timeline-title">{t("whoweare.timeline.2010.title")}</h2>
                            <span>{t("whoweare.timeline.2010.description")}</span>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="timeline-item">
                            <div className="timeline-year">2018</div>
                            <h2 className="timeline-title">{t("whoweare.timeline.2018.title")}</h2>
                            <span>{t("whoweare.timeline.2018.description")}</span>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="timeline-item">
                            <div className="timeline-year">2021</div>
                            <h2 className="timeline-title">{t("whoweare.timeline.2021.title")}</h2>
                            <span>{t("whoweare.timeline.2021.description")}</span>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="timeline-item">
                            <div className="timeline-year">2023</div>
                            <h2 className="timeline-title">{t("whoweare.timeline.2023.title")}</h2>
                            <span>{t("whoweare.timeline.2023.description")}</span>
                        </div>
                    </div>
                </Glider>
            </div>
        </div>
    )
}

export default Timeline;