import { useTranslation } from "react-i18next";
import './index.scss'
import { Link } from "react-router-dom";

const Partner = () => {

    const [t] = useTranslation("global")

    return(
        <div className="partner">
            <div className="bannerPartner" data-aos="fade-in">
                <div className="container">
                    <div className="infos">
                        <h1 data-aos="fade-down" data-aos-delay="100">{t("partner.title")}</h1>
                        <span data-aos="fade-down" data-aos-delay="200">{t("partner.description")}</span>
                        <Link to="/contato">
                            <button className="buttonOrder" data-aos="fade-down" data-aos-delay="300">
                                {t("partner.button")}
                            </button>
                        </Link>
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Partner;