import { useTranslation } from 'react-i18next';
import '../MainBanner/index.scss'
import { Link } from 'react-router-dom';

const BannerWhatsapp = () => {

    const [t] = useTranslation("global")

    return(
        <div className="mainBanner bgWhatsApp">
            <div className="container">
                <div className="caption">
                <h1 data-aos="fade-down">{t("bannerWhatsapp.title")}</h1>
                <span data-aos="fade-down" data-aos-delay="100">{t("bannerWhatsapp.description")}</span>
                <Link to="https://api.whatsapp.com/send?phone=5512997507760">
                    <button className="buttonOrder buttonWhats" data-aos="fade-down" data-aos-delay="200">
                        {t("bannerWhatsapp.button")}
                    </button>
                </Link>
                
                </div>
            </div>
        </div>
    )
}

export default BannerWhatsapp;